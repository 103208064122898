import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_DAY_SERVICE_PROVIDER, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemBaseServiceProviderPriceMaybe = props => {
  const { lineItems, intl } = props;

  const translationKey = 'OrderBreakdown.baseUnitDay';

  const unitPurchase = lineItems.find(
    item => item.code === LINE_ITEM_DAY_SERVICE_PROVIDER && !item.reversal
  );

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const attendees =
    unitPurchase && unitPurchase?.attendees ? unitPurchase.attendees.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  const message = attendees ? (
    <FormattedMessage
      id={`${translationKey}Attendees`}
      values={{ unitPrice, quantity, attendees }}
    />
  ) : (
    <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
  );

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>{message}</span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBaseServiceProviderPriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBaseServiceProviderPriceMaybe;
